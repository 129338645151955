exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-archives-jsx": () => import("./../../../src/pages/Archives.jsx" /* webpackChunkName: "component---src-pages-archives-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-homework-jsx": () => import("./../../../src/pages/homework.jsx" /* webpackChunkName: "component---src-pages-homework-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-homework-post-js": () => import("./../../../src/templates/homework-post.js" /* webpackChunkName: "component---src-templates-homework-post-js" */)
}

